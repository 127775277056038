var $ = require('jquery');

$(document).ready( function() {
    initHeader();  
});

function initHeader() {
    $('.hamburger').on('click', function() {
        $(this).toggleClass('is-active');

        if ( $(this).hasClass('is-active') ) {
            $('.menu-primary-navigation-container').attr('aria-expanded', true);
            let height = $('.menu-primary-navigation-container')[0].scrollHeight;
            $('.menu-primary-navigation-container').css('max-height', height);
        } else {
            $('.menu-primary-navigation-container').attr('aria-expanded', false);
            $('.menu-primary-navigation-container').css('max-height', 0);
        }
    })


    $(window).on('resize', function() {
        if ( window.matchMedia( '(min-width:768px)' ).matches ) {
            $('.menu-primary-navigation-container').attr({
                "style": null,
                "aria-expanded": null
            });
            $('.hamburger.is-active').removeClass('is-active');
        }
    })
}