var $ = require('jquery');

$(document).ready( function() {
    initCover();  
});

function initCover() {
    $('.page-section--main > .wp-block-cover, .page-section--main > .template-post > .wp-block-cover').each(function() {
        var prev = $(this).prev();
        
        $(this).addClass('--first');

        if(prev.length == 0) {
            $(this).addClass('--banner');
        }
        if(prev.hasClass('stories__featured-image')) {
            $(this).addClass('--banner');
        }
    })
    $('.page-section--main > .vimeo-content, .page-section--main > .template-post > .vimeo-content').each(function() {
        var prev = $(this).prev();

        $(this).find('.wp-block-cover').first().addClass('--first');

        if(prev.length == 0) {
            $(this).find('.wp-block-cover').first().addClass('--banner');
        }
    })
}