var $ = require('jquery');
var Flickity = require("flickity");
var jQueryBridget = require("jquery-bridget");

Flickity.setJQuery($);
jQueryBridget("flickity", Flickity, $);

$(document).ready(function() {
    initCarousels();
})


function initCarousels() {
    $('.carousel').each(function() {
        let $carousel = $(this);

        $carousel.on(' ready.flickity', function(e, index) {
            $carousel.find('.flickity-viewport').wrap('<div class="carousel-container">');
            $carousel.find('.carousel-container').append('<div class="carousel-hidden-area">');

            $(window).on('resize', function() {
                let container = $('.container').outerWidth();
                let viewportSides = ($(window).width() - container) / 2;
                $('.carousel-hidden-area').css('width', (container * 0.3) + viewportSides );
            }).trigger('resize');
        })
    
        $(window).on('load', function(){
            $carousel.flickity({
                cellAlign: 'right',
                pageDots: false,
                wrapAround: true,
                initialIndex: $carousel.find(".spotlight-listing__card").length - 1,
            })
        })
    })
}

