var $ = require('jquery');

$(document).ready( function() {
    initGroup();  
});

function initGroup() {
    $('.page-section--main > .wp-block-group, .page-section--main > .template-post > .wp-block-group').not('.is-style-post-container').each(function() {
        var prev = $(this).prev();
        $(this).addClass('--first');

        if(prev.length == 0) {
            $(this).addClass('--banner');
        }
    })

    $('.is-style-post-container').each(function() {
        $(this).children().wrapAll('<div class="post-container__content"></div>')
        $(this).find('.post-container__content').wrap('<div class="container"></div>');
    })


    $('.prev-next-story').each(function() {
        $(this).parent().addClass('prev-next-story-parent');
    })
}