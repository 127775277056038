var $ = require('jquery');

const arrow = '';
arrow += '<svg width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">';
arrow += '<path d="M30.7071 8.70711C31.0976 8.31658 31.0976 7.68342 30.7071 7.29289L24.3431 0.928932C23.9526 0.538408 23.3195 0.538408 22.9289 0.928932C22.5384 1.31946 22.5384 1.95262 22.9289 2.34315L28.5858 8L22.9289 13.6569C22.5384 14.0474 22.5384 14.6805 22.9289 15.0711C23.3195 15.4616 23.9526 15.4616 24.3431 15.0711L30.7071 8.70711ZM0 9H30V7H0L0 9Z" fill="#162224"/>';
arrow += '</svg>';

const eye = '';
eye += '<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">';
eye += '<g clip-path="url(#clip0_1420_3122)">';
eye += '<path d="M24 8.60054C16.9445 -1.87644 8.45808 -3.68377 -3.8147e-06 8.24046C5.07202 16.9101 14.1747 22.4291 24 8.60054ZM21.4286 8.52437C15.4357 17.5541 8.32349 17.5403 2.4085 8.49668C8.43683 -0.546909 15.4711 -0.533059 21.4286 8.52437Z" fill="#F5F5F5"/>';
eye += '<path d="M11.8299 3.67005C14.4651 3.69082 16.6823 5.85131 16.739 8.44113C16.7956 11.1833 14.4934 13.4684 11.6953 13.4407C9.04593 13.413 6.82869 11.2664 6.77202 8.66272C6.70827 5.93441 9.03176 3.64235 11.837 3.67005H11.8299ZM11.7236 10.7678C12.9916 10.7817 14.0329 9.74988 14.0046 8.50345C13.9834 7.32626 12.9775 6.34988 11.7803 6.34296C10.5123 6.32911 9.46388 7.36781 9.48513 8.60732C9.51346 9.78451 10.5123 10.7609 11.7165 10.7678H11.7236Z" fill="#F5F5F5"/>';
eye += '</g>';
eye += '<defs><clipPath id="clip0_1420_3122"><rect width="24" height="17" fill="white" transform="matrix(-1 0 0 1 24 0)"/></clipPath></defs>';
eye += '</svg>';


$(document).ready( function() {
    initButtons();  
});

function initButtons() {
    $('.wp-block-buttons .wp-block-button__link').each(function() {
        if (!$(this).parent().hasClass("is-style-outline")) {
            $(this).append(arrow);
        }
        if ($(this).parent().hasClass("is-style-outline")) {
            var value = $(this).attr('href');

            if(value == '#play') {
                $(this).append(eye);
            }
        }
    })
}