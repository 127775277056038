var $ = require('jquery');

var mobile;
var mobileSize = 781;
var videoPlayers = [];
var videoTimes = [];

var backgroundOptions = {
    background: true,
    autoplay: true,
    loop: true,
    byline: false,
    title: false
};
var modalOptions = {
    background: false,
    autoplay: true,
    loop: false,
    byline: false,
    title: false
};

$(document).ready( function() {
    initVideo();  
});

// Videos
function initVideo() {
	initVideoBG();
	initVideoMD();
}

// Video in background...
function initVideoBG() {

	var width = window.innerWidth;
	if (width <= mobileSize) { mobile = true; }
	else { mobile = false; }

	$(".vimeo-content").each(function() {
		var videoMobileID = $(this).find(".vimeo__player.--mobile.--BG").attr("id");
		var videoDesktopID = $(this).find(".vimeo__player.--desktop.--BG").attr("id");
		var hasLoop = ($(this).find(".vimeo__player.--desktop.--BG").attr("data-vimeo-id") != "");
		if (hasLoop) {
			if(mobile) { videoPlayers[videoMobileID] = new Vimeo.Player(videoMobileID, backgroundOptions); } 
			else { videoPlayers[videoDesktopID] = new Vimeo.Player(videoDesktopID, backgroundOptions); }

			//$(".vimeo-content").find('.wp-block-cover img').hide();
		} else {
			//?
		}
	});

	const desktopRatio = 16 / 9;
	const mobileRatio = 9 / 16;

	$(window).on('resize', function(){

		var width = window.innerWidth;
		if(width <= mobileSize) { mobile = true; }
		else { mobile = false; }

		$(".vimeo-content").each(function() {
			var height = $(this).height();
			var videoMobileID = $(this).find(".vimeo__player.--mobile.--BG").attr("id");
			var videoDesktopID = $(this).find(".vimeo__player.--desktop.--BG").attr("id");
			
			if(mobile) {
				videoPlayers[videoMobileID] = new Vimeo.Player(videoMobileID, backgroundOptions);
				if(videoPlayers[videoDesktopID] != undefined ) {
					videoPlayers[videoDesktopID].destroy();
				}
			} else {
				videoPlayers[videoDesktopID] = new Vimeo.Player(videoDesktopID, backgroundOptions);
				if(videoPlayers[videoMobileID] != undefined ) {
					videoPlayers[videoMobileID].destroy();
				}
			}

			// Background video...
			var video = $(this).find(".vimeo__player.--BG");
			var iframe = video.find("iframe");

			if(mobile) {
				var iframeWidth = width;
				var iframeHeight = iframeWidth / mobileRatio;
				$(iframe).css('height', iframeHeight);
				$(iframe).css('width', '100%');

				if (iframeHeight <= height) {
					var iframeHeight = height;
					var iframeWidth = iframeHeight * mobileRatio;
					$(iframe).css('height', '100%');
					$(iframe).css('width', iframeWidth);
				}

			} else {
				var iframeWidth = width;
				var iframeHeight = iframeWidth / desktopRatio;
				$(iframe).css('height', iframeHeight);
				$(iframe).css('width', '100%');

				if (iframeHeight <= height) {
					var iframeHeight = height;
					var iframeWidth = iframeHeight * desktopRatio;
					$(iframe).css('height', '100%');
					$(iframe).css('width', iframeWidth);
				}
			}
		});

	}).trigger('resize');

	setTimeout(function () {
		$(window).trigger('resize');
	}, 1000);
}

// Video in modal...
function initVideoMD() {

	$(".vimeo-content").find("a[href='#play']").on("click", function (e) {
		e.preventDefault();
		e.stopPropagation();

		$(this).parents(".vimeo-content").find('.vimeo-content__modal').show();
		
		// Play...
		var modal = $(this).parents(".vimeo-content").find('.vimeo-content__modal');
		var videoID = modal.find(".vimeo__player").attr("id");
		var videoPlayerMD = new Vimeo.Player(videoID, modalOptions);

		getVideoBG('pause');
		videoPlayerMD.play();

		// Close...
		modal.find(".js-button").on("click", function () {
			$(this).parents(".vimeo-content").find('.vimeo-content__modal').hide();

			getVideoBG('play');
			videoPlayerMD.pause();
		});


		//GTM events
		videoPlayerMD.off('play');
		videoPlayerMD.on('play', function(data) {
		    var obj = {
				event: "video",
				video_action: "play",
				video_url: this.element.getAttribute("src"),
				video_percent: data.percent * 100, 
				video_title: this.element.getAttribute("title")
			};
			console.log(obj);
		    dataLayer.push(obj);
		});

		videoPlayerMD.off('ended');
		videoPlayerMD.on('ended', function(data) {
		    var videoID = this.element.parentElement.getAttribute("id");
		    videoTimes[videoID] = 0;

		    var obj = {
				event: "video",
				video_action: "ended",
				video_url: this.element.getAttribute("src"),
				video_percent: data.percent, 
				video_title: this.element.getAttribute("title")
			};
			//console.log(obj);
		    dataLayer.push(obj);
		});

		videoPlayerMD.off('progress');
		videoPlayerMD.on('progress', function(data) {
		    //console.log(data);
		    var videoID = this.element.parentElement.getAttribute("id");
		    if (!videoTimes[videoID]) {
		    	videoTimes[videoID] = 25;
		    }
		    if ((data.percent * 100) < videoTimes[videoID]) {
		    	return;
		    }
		    //increment
		    var milestone = 0;
		    switch(videoTimes[videoID]) {
				case 25:
					milestone = 25;
					videoTimes[videoID] = 50;
					break;
				case 50:
					milestone = 50;
					videoTimes[videoID] = 75;
					break;
				case 75:
					milestone = 75;
					videoTimes[videoID] = 90;
					break;
				case 90:
					milestone = 90;
					videoTimes[videoID] = 100;
					break;
				default:
					return;
			}
		    
		    var obj = {
				event: "video",
				video_action: "progress",
				video_url: this.element.getAttribute("src"),
				video_percent: milestone, 
				video_title: this.element.getAttribute("title")
			};
			//console.log(obj);
		    dataLayer.push(obj);
		});

		return false;
	});
}


// Utilities //
function getVideoBG(options) {
	$(".vimeo-content").each(function() {
		var videoMobileID = $(this).find(".vimeo__player.--mobile.--BG").attr("id");
		var videoDesktopID = $(this).find(".vimeo__player.--desktop.--BG").attr("id");

		if(mobile) {
			if(videoPlayers[videoMobileID] != undefined ) {
				if(options == 'pause') { videoPlayers[videoMobileID].pause(); }
				else if(options == 'play') { videoPlayers[videoMobileID].play(); }
			}
		} else {
			if(videoPlayers[videoDesktopID] != undefined ) {
				if(options == 'pause') { videoPlayers[videoDesktopID].pause(); }
				else if(options == 'play') { videoPlayers[videoDesktopID].play(); }
			}
		}
	});
}