var $ = require('jquery');


$(document).ready( function() {
    initShare();  
});


function initShare() {
	$(".share-btn").on("click", function (e) {
		e.preventDefault();
		e.stopPropagation();
		$(this).toggleClass("--is-open").next().toggleClass("--is-open");
		return false;
	});
}