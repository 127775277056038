var $ = require('jquery');
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

$(document).ready(function() {
    storyPageFeaturedImage();
    resourceSidebarMedia();
})

function storyPageFeaturedImage() {
    let featuredImage = $('.stories__featured-image img');

    if ( featuredImage.length > 0 ) {
        featuredImage.wrap('<div class="post-container__featured-image is-hidden">');
        $('.is-style-post-container > .container').append($('.post-container__featured-image'));


        $(window).on('scroll', function(e) {
            let rect = $('.is-style-post-container')[0].getBoundingClientRect();
            if ( rect.top < ($(window).height() / 3) * 2 ) {
                $('.post-container__featured-image.is-hidden').removeClass("is-hidden");
            } else {
                $('.post-container__featured-image').addClass("is-hidden");
            }
        })
    }
}

function resourceSidebarMedia() {

    var colorsArray = [
        {
            "background-class": "has--background-color",
            "color": "#FFF"
        },
        {
            "background-class": "has--background-color",
            "color": "#162224"
        },
        {
            "background-class": "has-periwinkle-background-color",
            "color": "#B7BBFF"
        },
        {
            "background-class": "has-peach-background-color",
            "color": "#FFA269"
        },
        {
            "background-class": "has-sun-background-color",
            "color": "#FFDA48"
        },
        {
            "background-class": "has-blue-background-color",
            "color": "#1B79FF"
        },
        {
            "background-class": "has-orange-background-color",
            "color": "#F47932"
        },
        {
            "background-class": "has-cloud-pink-background-color",
            "color": "#E8DFD3"
        },
        {
            "background-class": "has-moon-background-color",
            "color": "#F5F5F5"
        },
        {
            "background-class": "has-midnight-background-color",
            "color": "#28373A"
        }
    ];
    if ( $('.wp-block-media-text.is-style-resource-sidebar').length > 0 ) {
        let imageContainer = $('<div class="post-container__featured-image">');
        $('.is-style-post-container > .container').append(imageContainer);

        
        $('.wp-block-media-text.is-style-resource-sidebar').each(function(index, el) {
            let _this = $(this);
            
            _this.attr('data-media-text-num', index);
            let image = _this.find('figure.wp-block-media-text__media img').clone();

            var classList = $(this)[0].className.split(' ')
            // console.log(classList);

            image.appendTo('.post-container__featured-image');

            let backgroundColor = '';
            if ( _this.attr('style') ) {
                // console.log(_this.attr('style'));
                backgroundColor = '#' + _this.attr('style').match(/[a-f0-9]{6}/i)[0];
                _this.css('background-color', 'transparent')
            } else {
                let attachedClass = '';
                colorsArray.forEach(element => {
                    if (_this.hasClass(element['background-class'])) {
                        attachedClass = element['background-class'];
                        backgroundColor = element['color'];
                    }
                });
                _this.removeClass(attachedClass);
            }

            let isActive = index == 0 ? 'active' : '';

            image.wrap('<div class="resource-sidebar__image '+isActive+'" data-background-color="'+backgroundColor+'">');

            ScrollTrigger.create({
                trigger: _this,
                start: 'top 50%',
                end: "bottom 50%",
                scrub: true,
                onEnter: ({progress, direction, isActive}) => {
                    let activeItem = $('.resource-sidebar__image.active');
                    activeItem.removeClass('active');
                    $('.resource-sidebar__image').eq(_this.attr('data-media-text-num')).addClass('active');
                    $('.post-container__featured-image-background').css('background-color', $('.resource-sidebar__image.active').attr('data-background-color') )
                },
                onEnterBack: ({}) => {
                    let activeItem = $('.resource-sidebar__image.active');
                    activeItem.removeClass('active');
                    $('.resource-sidebar__image').eq(_this.attr('data-media-text-num')).addClass('active');
                    $('.post-container__featured-image-background').css('background-color', $('.resource-sidebar__image.active').attr('data-background-color') )
                },
            })
        })

        $('.post-container__featured-image').append('<div class="post-container__featured-image-background">');

        if ( $('.resource-sidebar__image.active').attr('data-background-color') !== null ) {
            $('.post-container__featured-image-background').css('background-color', $('.resource-sidebar__image.active').attr('data-background-color') )
        }
        
        $(window).on('resize', function() {
            
            let container = $('.container').outerWidth();
            let viewportSides = ($(window).width() - container) / 2;
            let width = (container * 0.5) + viewportSides - (4.5 * 16);
            $('.post-container__featured-image-background').css('width', width );

            ScrollTrigger.refresh();
        }).trigger('resize');

    }
}

