var $ = require('jquery');

$(document).ready( function() {
    initColumns();  
});

function initColumns() {
    $('.page-section--main > .wp-block-columns, .page-section--main > .template-post > .wp-block-columns').each(function() {
        $(this).addClass('--first');
    })
}