
/**
 * Import node modules.
 */
// npm install smoothscroll-polyfill --save
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

/**
 * Require other JS libraries.
 */
// const $ = require('jquery');
const WebFont = require('webfontloader');
const lazysizes = require('./libs/lazysizes-5.3.1.js');
const jpInView = require('./libs/jpInView.js');


/**
 * Blocks
 */
const buttons = require('./blocks/buttons.js');
const cover = require('./blocks/cover.js');
const group = require('./blocks/group.js');
const columns = require('./blocks/columns.js');
const share = require('./blocks/share.js');
const vimeoContent = require('./blocks/vimeo-content.js');

/**
 * Components
 */
const carousel = require('./components/carousel.js');
const siteHeader = require('./components/site-header.js');

/**
 * Templates
 */
const single = require('./templates/single-page.js');


/*
// https://github.com/typekit/webfontloader

WebFont.load({
	// GOOGLE FONTS
	// google: {
	// 	families: [
	// 		'Barlow Condensed:300,400,500,600,700'
	// 	]
	// },
	// TYPEKIT
	// typekit: { id: 'abc123' },
	// CALLBACK FOR WHEN FONTS LOAD (OPTIONAL)
	// active: dje_fonts_active_cb
});

// Callback function for when the fonts are loaded.
function dje_fonts_active_cb() {

}
*/



// Use lazysizes to get lazy loading on css background images.
document.addEventListener('lazybeforeunveil', function (e) {
	try {
		var bg = e.target.getAttribute('data-bg');
    if (bg) {
			e.target.style.backgroundImage = 'url(' + bg + ')';
    }

	} catch (error) {
		console.log('Error with lazyload background:');
		console.log(error);
	}

});
